<template>
  <div class="box">
    <router-link to="/">
      <div id="btn-back"></div>
    </router-link>
    <div class="sun-box" title="积云教育">
      <router-link to="/jiyun_jiaoyu">
        <div id="sun">
          <img class="bg-sun" src="../../../src/assets/usian/bg_sun.png" />
        </div>
      </router-link>
      <div class="label-box">
        <div class="label left">
          <span class="text">积云教育</span>
        </div>
        <div class="label"></div>
      </div>
    </div>

    <div class="mercury-box" title="水星">
      <div id="mercury">
        <img class="bg-mercury" src="../../../src/assets/usian/bg_mercury.png" />
      </div>
      <!-- <div class="label-box">
        <div class="label"></div>
        <div class="label right">
          <span class="text font18">水星</span>
        </div>
      </div>-->
    </div>

    <div class="venus-box" title="金星">
      <div id="venus">
        <img class="bg-venus" src="../../../src/assets/usian/bg_venus.png" />
      </div>
      <!-- <div class="label-box">
        <div class="label"></div>
        <div class="label right">
          <span class="text font18">金星</span>
        </div>
      </div>-->
    </div>

    <div class="mars-box" title="物联网">
      <router-link to="/jiyun_major?major=mars">
        <div id="mars">
          <img class="bg-mars" src="../../../src/assets/usian/bg_mars.png" />
        </div>
      </router-link>
      <div class="label-box">
        <div class="label"></div>
        <div class="label right">
          <span class="text">物联网</span>
        </div>
      </div>
    </div>

    <div class="jupiter-box" title="大数据">
      <router-link to="/jiyun_major?major=jupiter">
        <div id="jupiter">
          <img class="bg-jupiter" src="../../../src/assets/usian/bg_jupiter.png" />
        </div>
      </router-link>
      <div class="label-box">
        <div class="label"></div>
        <div class="label right">
          <span class="text">大数据</span>
        </div>
      </div>
    </div>

    <div class="earth-box" title="地球">
      <router-link to="/jiyun_search">
        <div id="earth">
          <img class="bg-earth" src="../../../src/assets/usian/bg_earth.png" />
        </div>
      </router-link>
      <div class="label-box">
        <div class="label"></div>
        <div class="label right">
          <span class="text">市场区域</span>
        </div>
      </div>
    </div>

    <div class="moon-box" title="月亮">
      <div id="moon"></div>
      <!-- <div class="label-box">
        <div class="label"></div>
        <div class="label right">
          <span class="text font18">月亮</span>
        </div>
      </div>-->
    </div>

    <div class="saturn-box" title="人工智能">
      <router-link to="/jiyun_major?major=saturn">
        <div id="saturn">
          <img class="bg-saturn" src="../../../src/assets/usian/bg_saturn.png" />
        </div>
      </router-link>
      <div class="label-box">
        <div class="label left">
          <span class="text">人工智能</span>
        </div>
        <div class="label"></div>
      </div>
    </div>

    <div class="uranus-box" title="视觉设计">
      <router-link to="/jiyun_major?major=uranus">
        <div id="uranus">
          <img class="bg-uranus" src="../../../src/assets/usian/bg_uranus.png" />
        </div>
      </router-link>
      <div class="label-box">
        <div class="label"></div>
        <div class="label right">
          <span class="text">视觉设计</span>
        </div>
      </div>
    </div>

    <div class="neptune-box" title="前端云计算">
      <router-link to="/jiyun_major?major=neptune">
        <div id="neptune">
          <img class="bg-neptune" src="../../../src/assets/usian/bg_neptune.png" />
        </div>
      </router-link>
      <div class="label-box">
        <div class="label"></div>
        <div class="label right">
          <span class="text">前端云计算</span>
        </div>
      </div>
    </div>

    <div class="pluto-box" title="全栈">
      <router-link to="/jiyun_major?major=pluto">
        <div id="pluto">
          <img class="bg-pluto" src="../../../src/assets/usian/bg_pluto.png" />
        </div>
      </router-link>
      <div class="label-box">
        <div class="label"></div>
        <div class="label right">
          <span class="text">全栈</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from "three/build/three.min.js";
import sun_1024 from "../../../src/assets/usian/sun_1024.jpg";
import mercury_1024 from "../../../src/assets/usian/mercury_1024.jpg";
import venus_1024 from "../../../src/assets/usian/venus_1024.jpg";
import mars_1024 from "../../../src/assets/usian/mars_1024.jpg";
import jupiter_1024 from "../../../src/assets/usian/jupiter_1024.jpg";
import earth_atmos_2048 from "../../../src/assets/usian/earth_1024.jpg";
import moon_1024 from "../../../src/assets/usian/moon_1024.jpg";
import saturn_1024 from "../../../src/assets/usian/saturn_1024.jpg";
import uranus_1024 from "../../../src/assets/usian/uranus_1024.jpg";
import neptune_1024 from "../../../src/assets/usian/neptune_1024.jpg";
import pluto_1024 from "../../../src/assets/usian/pluto_1024.jpg";
let i = 0; //给每个渲染器一个标签，方便及时清除
let obj = {}; //存储渲染器

export default {
  name: "solar_system",
  mounted() {
    this.init();
  },
  beforeDestroy() {
    for (let j = 0; j < 11; j++) {
      if (obj["renderer_" + j]) {
        obj["renderer_" + j].forceContextLoss();
        obj["renderer_" + j].domElement = null;
        obj["renderer_" + j] = null;
      }
    }
  },
  methods: {
    init: function() {
      this.createStar({
        type: "sun",
        width: this.getSize("sun").width,
        size: [this.getSize("sun").width / 2, 24, 24],
        img: sun_1024
      });
      this.createStar({
        type: "mercury",
        width: this.getSize("mercury").width,
        size: [this.getSize("mercury").width / 2, 24, 24],
        img: mercury_1024
      });
      this.createStar({
        type: "venus",
        width: this.getSize("venus").width,
        size: [this.getSize("venus").width / 2, 24, 24],
        img: venus_1024
      });
      this.createStar({
        type: "mars",
        width: this.getSize("mars").width,
        size: [this.getSize("mars").width / 2, 24, 24],
        img: mars_1024
      });
      this.createStar({
        type: "jupiter",
        width: this.getSize("jupiter").width,
        size: [this.getSize("jupiter").width / 2, 24, 24],
        img: jupiter_1024
      });
      this.createStar({
        type: "earth",
        width: this.getSize("earth").width,
        size: [this.getSize("earth").width / 2, 24, 24],
        img: earth_atmos_2048
      });
      this.createStar({
        type: "moon",
        width: this.getSize("moon").width,
        size: [this.getSize("moon").width / 2, 24, 24],
        img: moon_1024
      });
      this.createStar({
        type: "saturn",
        width: this.getSize("saturn").width,
        size: [this.getSize("saturn").width / 2, 24, 24],
        img: saturn_1024
      });
      this.createStar({
        type: "uranus",
        width: this.getSize("uranus").width,
        size: [this.getSize("uranus").width / 2, 24, 24],
        img: uranus_1024
      });
      this.createStar({
        type: "neptune",
        width: this.getSize("neptune").width,
        size: [this.getSize("neptune").width / 2, 24, 24],
        img: neptune_1024
      });
      this.createStar({
        type: "pluto",
        width: this.getSize("pluto").width,
        size: [this.getSize("pluto").width / 2, 24, 24],
        img: pluto_1024
      });
    },
    createStar: function(params) {
      //场景
      var scene = new THREE.Scene();

      //灯光
      //点光源
      var pointLight = new THREE.PointLight(0xffffff);
      pointLight.position.set(10, 0, 10);
      scene.add(pointLight);

      //太阳光
      let directionalLight = new THREE.DirectionalLight(0xffffff, 2.0);
      directionalLight.position.set(0, 0, 10000).normalize();
      scene.add(directionalLight);

      //摄像
      var camera = new THREE.PerspectiveCamera(60, 1, 1, 1000);
      camera.position.set(params.width * 0.72, 0, params.width * 0.72); //设置相机位置
      camera.lookAt(scene.position); //设置相机方向(指向的场景对象)

      //演员
      var geometry = new THREE.SphereBufferGeometry(
        params.size[0],
        params.size[1],
        params.size[2]
      );
      var material = new THREE.MeshPhongMaterial({
        color: 0xffffff
      });
      var textureLoader = new THREE.TextureLoader();
      textureLoader.load(params.img, function(tex) {
        material.map = tex;
        material.map.encoding = THREE.sRGBEncoding;
        material.needsUpdate = true;
      });
      var mesh = new THREE.Mesh(geometry, material);
      scene.add(mesh);

      //action
      let name = "renderer_" + i++;
      obj[name] = new THREE.WebGLRenderer({ antialias: true, alpha: true }); //渲染器
      obj[name].setClearAlpha(0);
      obj[name].setSize(params.width, params.width);
      document.getElementById(params.type).appendChild(obj[name].domElement);
      render(obj[name]);

      function render(r) {
        r.render(scene, camera);
        mesh.rotateY(0.001);
        requestAnimationFrame(function() {
          render(r);
        });
      }
    },
    getSize(domStr) {
      var width = document.getElementById(domStr).offsetWidth;
      var height = document.getElementById(domStr).offsetHeight;
      return { width, height };
    }
  }
};
</script>

<style style lang="scss" scoped>
.box {
  margin: 0;
  background: url("../../../src/assets/usian/bg.jpg") center no-repeat;
  background-size: cover;
  position: relative;
  height: 100vh;
}
#btn-back {
  width: 48px;
  height: 48px;
  background: #ddd url(../../../src/assets/usian/arrow-top.png) center no-repeat;
  position: fixed;
  top: 0;
  left: 48.53vw;
}
@media screen and (max-width: 1366px) {
  .sun-box,
  .mercury-box,
  .venus-box,
  .mars-box,
  .jupiter-box,
  .earth-box,
  .moon-box,
  .saturn-box,
  .uranus-box,
  .neptune-box,
  .pluto-box {
    width: 136px !important;
  }
  .label-box {
    width: 200px !important;
  }
}

.sun-box,
.mercury-box,
.venus-box,
.mars-box,
.jupiter-box,
.earth-box,
.moon-box,
.saturn-box,
.uranus-box,
.neptune-box,
.pluto-box {
  position: absolute;
  width: 272px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* 积云教育 */
.sun-box {
  top: 20.42vh;
  left: 8.94vw;
}
#sun {
  width: 240px;
  height: 240px;
  position: relative;
}
.bg-sun {
  position: absolute;
  z-index: 1;
  top: -110px;
  left: -110px;
}
/* 水星 */
.mercury-box {
  top: 18.42vh;
  left: 26.84vw;
}
#mercury {
  width: 78px;
  height: 78px;
  position: relative;
}
.bg-mercury {
  position: absolute;
  z-index: 1;
  top: -40px;
  left: -45px;
}
/* 金星 */
.venus-box {
  top: 47.5vh;
  left: 20.94vw;
}
#venus {
  width: 80px;
  height: 80px;
  position: relative;
}
.bg-venus {
  position: absolute;
  z-index: 1;
  top: -52px;
  left: -65px;
}
/* 物联网 */
.mars-box {
  top: 62.91vh;
  left: 15.15vw;
}
#mars {
  width: 110px;
  height: 110px;
  position: relative;
}
.bg-mars {
  position: absolute;
  z-index: 1;
  top: -45px;
  left: -131px;
}
/* 大数据 */
.jupiter-box {
  top: 38.41vh;
  left: 35.63vw;
}
#jupiter {
  width: 156px;
  height: 156px;
  position: relative;
}
.bg-jupiter {
  position: absolute;
  z-index: 1;
  top: -10px;
  left: -8px;
}
/* 地球 */
.earth-box {
  top: 30vh;
  left: 57.78vw;
}
#earth {
  width: 120px;
  height: 120px;
  position: relative;
}
.bg-earth {
  position: absolute;
  z-index: 1;
  top: -76px;
  left: -98px;
}
/* 月亮 */
.moon-box {
  top: 40.58vh;
  left: 50.57vw;
}
#moon {
  width: 46px;
  height: 46px;
  position: relative;
}
/* 人工智能 */
.saturn-box {
  top: 65.25vh;
  left: 49.15vw;
}
#saturn {
  width: 120px;
  height: 120px;
  position: relative;
}
.bg-saturn {
  position: absolute;
  z-index: 1;
  top: -10px;
  left: -116px;
  height: 160px;
}
/* 新媒体 */
.uranus-box {
  top: 51.83vh;
  left: 63vw;
}
#uranus {
  width: 100px;
  height: 100px;
  position: relative;
}
.bg-uranus {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
/* 大学全栈 */
.neptune-box {
  top: 74.91vh;
  left: 59.94vw;
}
#neptune {
  width: 98px;
  height: 98px;
  position: relative;
}
.bg-neptune {
  position: absolute;
  z-index: 1;
  top: -25px;
  left: -28px;
}
/* 高中全栈 */
.pluto-box {
  top: 53.16vh;
  left: 79.73vw;
}
#pluto {
  width: 80px;
  height: 80px;
  position: relative;
}
.bg-pluto {
  position: absolute;
  z-index: 1;
  top: -20px;
  left: -30px;
}
.label-box {
  display: flex;
  width: 272px;
}
.label {
  flex: 1;
  height: 24px;
  position: relative;
  top: 20px;
}

.left {
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.right {
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  text-align: right;
}
.earth-box .right {
  border-left: none;
  position: relative;
  top: -50px;
  left: 80px;
}

.left > .text {
  color: #fff;
  font-size: 24px;
  position: relative;
  top: -20px;
  left: -20px;
}
.right > .text {
  color: #fff;
  font-size: 24px;
  position: relative;
  top: -10px;
}
.font18 {
  font-size: 18px !important;
}
</style>
